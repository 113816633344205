import React, { useMemo, useEffect } from 'react';
import { useQueryString, useWebSend } from '@strikelabs/vega';

import useUnsubscribe from 'hooks/useUnsubscribe';
import useMount from 'hooks/useMount';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import Success from 'components/Pages/Unsubscribe/Success';
import Failed from 'components/Pages/Unsubscribe/Failed';
import Loading from 'components/Pages/Unsubscribe/Loading';
import BrandImg from 'images/strike-brand.png';

const Unsubscribe = () => {
  const { mounted } = useMount();

  const { token, type } = useQueryString();
  const { unsubscribeState, unsubscribe, invalid, message, success, error } =
    useUnsubscribe({
      token,
      type,
      useSendHook: useWebSend,
    });

  useEffect(() => {
    if (invalid) {
      return;
    }
    unsubscribe();
  }, []);

  const content = useMemo(() => {
    if (!mounted) return <Loading />;

    if (invalid || error) {
      return <Failed message={message} />;
    }

    if (success) {
      return <Success message={message} />;
    }

    return <Loading />;
  }, [unsubscribeState, invalid, error, success, mounted]);

  return (
    <Layout>
      <SEO
        metaImage={BrandImg}
        title="Unsubscribe | Strike"
        noIndex
        canonical="/legal/unsubscribe"
      />
      {content}
    </Layout>
  );
};

export default Unsubscribe;
