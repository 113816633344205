import React from 'react';
import PropTypes from 'prop-types';

import { HeroSection } from 'components/CMS';

import { Container } from '../style';

const Failed = ({ message }) => (
  <Container>
    {message ? (
      <HeroSection headline={message} />
    ) : (
      <HeroSection
        headline={'Something Went Wrong'}
        intro={"We couldn't unsubscribe you, please try again"}
      />
    )}
  </Container>
);

Failed.propTypes = {
  message: PropTypes.string,
};

export default Failed;
