import React from 'react';
import PropTypes from 'prop-types';
import { Text, Box } from '@strikelabs/luna';

import { TextSectionContainer } from 'components/common';
import { LegacyTextSection, HeroSection } from 'components/CMS';
import { Spacer } from 'templates/Page/style';

import { Container } from '../style';

const Success = ({ message }) => (
  <Container>
    <HeroSection headline={message} intro={"We're sorry to see you go."} />
    <Spacer />
    <TextSectionContainer>
      <LegacyTextSection
        body={
          <>
            <Text ext>
              You&apos;ve been unsubscribed and will no longer receive marketing
              emails from Strike. Please allow up to 28 days for us to update
              all our systems.
            </Text>
            <Text ext my={1}>
              Thanks from everyone at Strike.
            </Text>
            <Text ext>
              Having second thoughts? It&apos;s OK. You can always contact us.
            </Text>
          </>
        }
        imageAlignment="left"
        primaryCta={{ label: 'Contact us', url: '/contact-us' }}
      />
    </TextSectionContainer>
    <Box pb={4} />
  </Container>
);

Success.propTypes = {
  message: PropTypes.string,
};

Success.defaultProps = {
  message: "You've been unsubscribed successfully!",
};

export default Success;
