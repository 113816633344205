import { useEffect, useState } from 'react';
import { SUCCESS } from '@strikelabs/vega';

const getEndpoint = type => {
  switch (type) {
    case 'saved_search_notification': {
      return 'listing-search/unsubscribe';
    }
    case 'marketing_preference': {
      return 'people/marketing/unsubscribe';
    }
    default: {
      return null;
    }
  }
};

const useUnsubscribe = ({ type, token, useSendHook }) => {
  const endpoint = getEndpoint(type);
  const [status, setStatus] = useState({
    success: false,
    error: false,
    message: null,
  });

  const [unsubscribeState, unsubscribe] = useSendHook({
    url: `${process.env.GATSBY_API_URL}/${endpoint}/${token}`,
  });

  useEffect(() => {
    /**
     * Entire request failed
     */
    if (unsubscribeState.error) {
      return setStatus({
        success: false,
        error: true,
        message: null,
      });
    }

    if (unsubscribeState.data && unsubscribeState.status === SUCCESS) {
      /**
       * Request succeeds
       */
      if (unsubscribeState.data.success === true) {
        return setStatus({
          success: true,
          error: false,
          message: unsubscribeState.data.msg,
        });
      }

      /**
       * Request is okay but already unsubscribed
       */
      if (unsubscribeState.data.success === false) {
        setStatus({
          success: false,
          error: true,
          message: unsubscribeState.data.error,
        });
      }
    }
  }, [unsubscribeState.status]);

  return {
    unsubscribeState,
    unsubscribe,
    invalid: !endpoint || !token,
    ...status,
  };
};

export default useUnsubscribe;
