import React from 'react';
import { Loader } from '@strikelabs/luna';

import { Container } from '../style';
import { Inner } from './style';

const Loading = () => (
  <Container>
    <Inner>
      <Loader fullSize />
    </Inner>
  </Container>
);

export default Loading;
